import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
//import fetch from "node-fetch";
import gql from "graphql-tag";
import { getBrowser } from "./functions";

var endpoint = `${process.env.REACT_APP_GRAPHQL}`; //environment variable for graphql endpoint
if (endpoint === "undefined") endpoint = "/server"; //for test environment.

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: endpoint,
  fetch: fetch
});

export const client = new ApolloClient({
  cache,
  link
});

const MUTATION_TRACKPAGE = gql`
  mutation(
    $page: String
    $search: String
    $action: String
    $actioninfo: String
    $abconfig: String
    $pagetrack: String
    $browser: Browser
    $screenwidth: Int
  ) {
    trackpage(
      page: $page
      search: $search
      action: $action
      actioninfo: $actioninfo
      abconfig: $abconfig
      pagetrack: $pagetrack
      screenwidth: $screenwidth
      browser: $browser
    )
  }
`;

const MUTATION_SENDOFFERREQUEST = gql`
  mutation(
    $name: String
    $email: String!
    $offer: String!
  ) {
    sendofferrequest(
      email: $email
      name: $name
      offer: $offer
    )
  }
`;

const MUTATION_SENDUNSUBSCRIBEREQUEST = gql`
  mutation(
    $reason: String
    $email: String!
  ) {
    sendunsubscriberequest(
      email: $email
      reason: $reason
    )
  }
`;

export function trackpage({
  page,
  search,
  action,
  actioninfo,
  abconfig,
  pagetrack
}) {
  var screenwidth =
    window.innerWidth > 0 ? window.innerWidth : window.screen.width;
  client.mutate({
    mutation: MUTATION_TRACKPAGE,
    variables: {
      page: page, //the page
      search: search, //search parameters
      action: action,
      actioninfo: actioninfo,
      abconfig: abconfig,
      pagetrack: pagetrack,
      screenwidth: screenwidth,
      browser: getBrowser()
    }
  });
}

export async function sendOfferRequest( name, email, offer, callback ) {
  await client.mutate({
    mutation: MUTATION_SENDOFFERREQUEST,
    variables: {
      name: name,
      email: email,
      offer: offer
    }
  })
  .then(result => {
    console.log(result.data.sendofferrequest)
    return callback(result.data.sendofferrequest)
  })
  .catch(() => {return callback(false)})
}

export async function sendUnsubscribeRequest( email, reason, callback ) {
  await client.mutate({
    mutation: MUTATION_SENDUNSUBSCRIBEREQUEST,
    variables: {
      reason: reason,
      email: email
    }
  })
  .then(result => {return callback(result.data.sendunsubscriberequest)})
  .catch(() => {return callback(false)})
}


