import React, { useEffect } from "react";
import {
  BodyDiv,
  ProductShot,
  PointTitle,
  GridTeam,
  GridCVP,
  SquareButton,
  Lightning,
  TeamImage,
  WheelImage,
  Carousel,
  Card,
  Section,
  CarouseCard,
  LeftButton
} from "./components";

import tickboxes from "../src/images/tickboxes.svg";
import explorer from "../src/images/explorer.svg";
import compass from "../src/images/compass.svg";

import { Link } from "react-router-dom";
import { trackpage } from "./trackpage";
//import { abctest33 } from "./ABtesting";

const ablightning = "lightning"; //abtest50("lightning", "nolightning");
const abbutton = "Learn more" //abctest33("Set a goal", "Create a wheel", "Learn more");
//Sessions Campaign Data 3 values: Set a Goal = 11, Create a wheel = 10, See a wheel = 4
//This should measure the % of click throughs.

function Home({setFilter}) {
  useEffect(() => {
    document.title = `SliceGoal`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      abconfig: "button=" + abbutton
    });
  });
  return (
    <BodyDiv style={{ textAlign: "center" }}>
      <title>SliceGoal Coaching Home</title>
      <GridTeam>
        <div style={{ gridArea: "left", margin: "20px" }}>
          {ablightning === "lightning" ? (
            <>
              <PointTitle style={{ maxWidth: "370px", marginTop: "50px" }}>
              Your Secret Weapon for Getting Stuff Done.
              </PointTitle>
              <LeftButton>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.slicegoal.com/app/?page=signup"
                >
                  <SquareButton float={"left"}>
                    {abbutton}
                  </SquareButton>
                </a>
              </LeftButton>
            </>
          ) : (
            <>
              <PointTitle style={{ maxWidth: "600px", marginTop: "50px" }}>
              Next generation goal smasher. If you're passionate about learning and achieving your life goals, Slicegoal is for you!
              </PointTitle>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.slicegoal.com/app/?page=signup"
              >
                <SquareButton float={"left"} margin={20}>
                  Get started
                </SquareButton>
              </a>
            </>
          )}
        </div>
        <div
          style={{
            gridArea: "right",
            display: 'flex'
          }}
        >
          <ProductShot />
        </div>
      </GridTeam>
      <GridCVP>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gridArea: "leftcvp"
          }}
        >
          <TeamImage />
        </div>
        <div
          style={{
            gridArea: "rightcvp",
            display: "flex",
            alignItems: "center",
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Section>
            <div
              style={{ color: "#86E0F8", fontSize: "14px", margin: "5px 0" }}
            >
              {"CURATED FOR YOU"}
            </div>
            <div
              style={{ fontSize: "24px", fontWeight: "800", margin: "10px 0" }}
            >
              {
                "Productivity tips designed for you to reach your full potential."
              }
            </div>
            <div
              style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}
            >
              {
                "Ask for tips that are aligned to your challenges to make things easier for you. Find your flow. SliceGoal is your coach."
              }
            </div>
          </Section>
        </div>
      </GridCVP>
        <Carousel>
            {/* <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'}/>
            <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}/>
            <PromotionCard name={'Target insights that matter to you most'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'}/> */}
            <PromotionCard name={'Focus on the right goals at the right time'} text={'You know which goals to focus on for your next step. Connect your purpose to outcomes.'} image={tickboxes} imageheight={'90px'}/>
            <PromotionCard name={'Get unblocked and keep on track'} text={'Nudges to get unblocked. You’ll be back on track before you know it.'}  image={explorer} imageheight={'110px'}/>
            <PromotionCard name={'Insights that have the most impact'} text={'Collect insights from people and keep your own insights to help you achieve any goal.'} image={compass} imageheight={'110px'}/>
        </Carousel>
      <GridCVP>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gridArea: "rightcvp"
          }}
        >
          <WheelImage />
        </div>
        <div
          style={{
            gridArea: "leftcvp",
            display: "flex",
            alignItems: "center"
          }}
        >
          <Section>
            <div
              style={{ color: "#86E0F8", fontSize: "14px", margin: "5px 0" }}
            >
              {"SELF-GROWTH"}
            </div>
            <div
              style={{ fontSize: "24px", fontWeight: "800", margin: "10px 0" }}
            >
              {
                "Visualise your goals for clarity" //success?
              }
            </div>
            <div
              style={{ fontSize: "16px", margin: "20px 0", color: "#9499A3" }}
            >
              {
                "Get your goals organised so you can track your progress. Keep a link between the areas you want to focus on and the goals to get you moving forward."
              }
            </div>
          </Section>
        </div>
      </GridCVP>

      <PointTitle style={{margin:'20px', textAlign: "center"}}>
        Start here
      </PointTitle>

      <div style={{ color: "#9499A3", margin: '10px', marginTop: "0px" }}>
        We've done half the work for you.
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "50px" }}
      >
        <Link to={"/templates"}>
        <SquareButton
          onClick={() => setFilter("business")}
          float={"none"}
          margin={10}
          //light={tagfilter !== "business"}
        >
          Business
        </SquareButton>
        </Link>
        <Link to={"/templates"}>
        <SquareButton
          onClick={() => setFilter("life")}
          float={"none"}
          margin={10}
          //light={tagfilter !== "life"}
        >
          Lifestyle
        </SquareButton>
        </Link>
        {/* <Link to={"/templates"}>
        <SquareButton
          onClick={() => setFilter("project")}
          float={"none"}
          margin={10}
          light={tagfilter !== "project"}
        >
          Project
        </SquareButton>
        </Link> */}
      </div>
    </BodyDiv>
  );
}

export default Home;

function PromotionCard({name, text, image, imageheight}) {
    return (
      <Card>
        <div style={{height:'150px', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <Lightning />
        </div>
        <CarouseCard>{name}</CarouseCard>
        <div style={{fontSize: "14px", color: "#9499A3", height:'100px', marginTop:'10px'}}>{text}</div>
      </Card>
    );
  }