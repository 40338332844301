import React, { useEffect } from "react";
import {
  BlogContainer,
  CenterAlign,
  Header,
  Body,
  PointTitle,
  SquareButton
} from "./components";
import coachingchat from "./images/Coaching Chat.png"
import insightimage from "./images/Insight Images.png"
import { trackpage } from "./trackpage";

export default function Rebrand() {
  useEffect(() => {
    document.title = `Cavestep to SliceGoal Rebrand`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <div style={{fontFamily: 'Open Sans, Karla'}}>
      <CenterAlign>
        <BlogContainer>
          <PointTitle align={'left'} style={{ marginLeft: "0px", marginBottom:'10px' }}>
            Cavestep is now SliceGoal!
          </PointTitle>
          <Body>
            {
              'We have changed our name to SliceGoal.'
            }
          </Body>
          <Body>
            {
              "SliceGoal represents what we hope to deliver to our community. The ability to slice goals and achieve the things you want in life."
            }
          </Body>
          <Body>
            {
              "Goal setting is a powerful tool. And if you make it a habit, you can reap the rewards. If you also slice those goals into steps and pieces, then you can have a clear path to your achieving your goals."
            }
          </Body>
          <Body>
            {
              <b>Think of SliceGoal as a way of slicing your goals!</b>
            }
          </Body>
          <Body>
            {
              "We've also released a bunch of new features!"
            }
          </Body>
          <div style={{display: 'flex', justifyContent: 'left', fontFamily: 'Open Sans, Karla'}}><ol>
            <li style={{fontSize: '22px', marginBottom: '10px', fontFamily: "'Open Sans', Karla"}}>{'Coaching Chat'}</li>
            <li style={{fontSize: '22px', marginBottom: '10px', fontFamily: 'Open Sans, Karla'}}>{'Sources link to Insights'}</li>
            <li style={{fontSize: '22px', marginBottom: '10px', fontFamily: 'Open Sans, Karla'}}>{'Focus Timers for Tasks'}</li>
            <li style={{fontSize: '22px', marginBottom: '10px', fontFamily: 'Open Sans, Karla'}}>{'SubTasks'}</li>
            <li style={{fontSize: '22px', marginBottom: '10px', fontFamily: 'Open Sans, Karla'}}>{'Upload images to insights'}</li>
          </ol></div>
          <div style={{display: 'flex', justifyContent: 'center' , marginTop: '50px'}}>
            <img
              style={{ height: "300px" }}
              id="demo"
              alt="demo"
              src={coachingchat}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{fontSize: '10px', marginBottom: '30px', marginTop: '15px', fontWeight: '700'}}>Coaching Chat</div>
          </div>
          <div style={{display: 'flex', justifyContent: 'center' , marginTop: '50px'}}>
            <img
              style={{ height: "500px" }}
              id="demo"
              alt="demo"
              src={insightimage}
            />
          </div>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{fontSize: '10px', marginBottom: '30px', marginTop: '30px', fontWeight: '700'}}>Insight Images</div>
          </div>

          <div
              style={{
                textAlign: "center",
                marginBottom: "30px"
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.slicegoal.com/app/?page=login&referrer=rebrand_webpage"
              >
                <SquareButton float={"none"} style={{ margin: "70px 0" }}>
                  Check it Out
                </SquareButton>
              </a>
            </div>
        
          {/* <Header>Our Approach</Header>
        <Attribute>coming soon...</Attribute> */}
         <Body>
            <Header style={{ marginLeft: "0px" }}>We'd love to hear from you.</Header>
            <Body>
              {
                "If you have ideas on how Slice Goal could be improved to make your life even better. Please reach out and let us know your ideas."
              }
            </Body>
            
            <div style={{fontSize:'16px', fontWeight: '700'}}>{"Daniel Schrader, Founder"}</div>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:daniel@slicegoal.com"
            >
              <div style={{fontSize:'16px'}}>{"daniel@slicegoal.com"}</div>
            </a>
            
            {/*  <Body style={{ marginBottom: "5px" }}>Mail To:</Body>
            <Body>
              {"co Lateral Products, 1-3 Albert St, Blackburn, Victoria, 3130"}
            </Body>*/}
          </Body> 
        </BlogContainer>
      </CenterAlign>
    </div>
  );
}
