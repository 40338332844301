export const validateemail = email => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

export function getBrowser(){
    const userAgent = navigator.userAgent;
    let browser = "unknown";
    // Detect browser name
    browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
    browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
    browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
    browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
    browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
    browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
    browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
    browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
    browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;

    // detect browser version
    let browserversion = detectversion(userAgent,browser)
    return {name: browser, version: browserversion}
    
}

function detectversion(userAgent, browser){
    switch (browser) {
        case 'UCBrowser': return `${browserVersion(userAgent,/(ucbrowser)\/([\d.]+)/i)}`;
        case 'Edge': return `${browserVersion(userAgent,/(edge|edga|edgios|edg)\/([\d.]+)/i)}`;
        case 'GoogleBot': return `${browserVersion(userAgent,/(googlebot)\/([\d.]+)/i)}`;
        case 'Chromium': return `${browserVersion(userAgent,/(chromium)\/([\d.]+)/i)}`;
        case 'Firefox': return `${browserVersion(userAgent,/(firefox|fxios)\/([\d.]+)/i)}`;
        case 'Chrome': return `${browserVersion(userAgent,/(chrome|crios)\/([\d.]+)/i)}`;
        case 'Safari': return `${browserVersion(userAgent,/(version)\/([\d.]+)/i)}`;
        case 'Opera': return `${browserVersion(userAgent,/(opera|opr)\/([\d.]+)/i)}`;
        case 'IE': {const version = browserVersion(userAgent,/(trident)\/([\d.]+)/i);
            // IE version is mapped using trident version
            // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
            return version ? `${parseFloat(version) + 4.0}` : `7.0`;}
        default: return `unknown/0.0.0.0`
    }
}

function browserVersion(userAgent,regex){
    return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
}