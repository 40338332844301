import React, { useEffect, useState } from "react"
import {
  PointTitle,
  BodyDiv,
  SquareButton
} from "./components"
/* import { Link } from "react-router-dom"; */

import { WheelCarousel } from "./wheel"
import { trackpage } from "./trackpage"

export default function Templates({tagfilter}) {
  useEffect(() => {
    document.title = `Templates`
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  const [filter, settag] = useState(tagfilter);

  return (
    <BodyDiv>
      <title>SliceGoal Templates</title>
      <div style={{textAlign: "center", margin:'auto', marginTop: '60px'}}>
        <PointTitle style={{textAlign: "center"}}>
          Start here.
        </PointTitle>
        <div style={{color: "#9499A3"}}>
          We've done half the work for you.
        </div>
        <div id={'filters'} style={{marginTop:'20px'}}>
          <SquareButton
          onClick={() => settag("")}
          float={"none"}
          //margin={10}
          light={filter !== ""}
          >
            All
          </SquareButton>
          <SquareButton
            onClick={() => settag("business")}
            float={"none"}
            margin={10}
            light={filter !== "business"}
          >
            Business
          </SquareButton>
          <SquareButton
            onClick={() => settag("life")}
            float={"none"}
            //margin={10}
            light={filter !== "life"}
          >
            Lifestyle
          </SquareButton>
        </div>
      </div>
      <WheelCarousel tag={filter} />
    </BodyDiv>
  );
}
