import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import {
  Body,
  PointTitle,
  Attribute,
  GridCVP,
  HabitBuilderHeroImage,
  Section,
  BodyDiv,
  SquareButton
} from "./components";
import { trackpage, sendOfferRequest } from "./trackpage";
import TextField from '@material-ui/core/TextField'

import { abtest50, abctest33 } from "./ABtesting";
import { validateemail } from "./functions";
const showpolicy = abtest50(true,false)
const abtitle = '5 Step Guide to Build Habits Quickly\n\n Without the Struggle' //abtest50("Build Your Habits in 5 simple steps. Guaranteed.", '5 Step Guide to Build Habits Quickly\n\n Without the Struggle');
const abbutton = abctest33("SEND MY FREE COPY NOW", "Claim Your FREE Copy Now", "Download Now")
const grandfather = abtest50('freesession','profile')


function Blog() {
  let navigate = useNavigate();
  function callback(response) {
    console.log("callback: " + response)
    if(response){
      //either show a free coaching session or offer a free slicegoal account/profile.
      if (grandfather === "freesession") navigate("../offer/building-habits/success?email=" + email)
      else navigate("../offer/building-habits/slicegoal?email=" + email)
      toast.success(response, {duration: 4000})
    } else {
      toast.error("Hmmm, something went wrong. Please try again.")
    }
  }
  useEffect(() => {
    document.title = `Free Habit Building Guide - 5 steps`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      abconfig: "title=" + abtitle + "&button=" + abbutton + "&policy=" + showpolicy
    })
  },[])
  //const [name, setname] = useState("")
  const [email, setemail] = useState("")
  return (
    <BodyDiv style={{ textAlign: "center" }}>
        <PointTitle align={'center'} lineheight='20px' style={{margin: '50px auto', maxWidth: "700px", marginTop: "50px" }}>
          {abtitle}
        </PointTitle>
        <Attribute style={{margin: '20px'}} align='center'>This FREE 5 step guide will help you get there easily!</Attribute>
        <GridCVP>
        <div
          style={{
            gridArea: "leftcvp"
          }}
        >
          <HabitBuilderHeroImage />
        </div>
        <div
          style={{
            gridArea: "rightcvp",
            display: "flex",
            alignItems: "center",
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Section>
            <Body>
              {
                "For a limited time, we'll send you a FREE copy of our guide. Simply enter your email address below and a copy of the PDF will instantly be sent to your inbox."
              }
            </Body>
            <Body style={{fontSize: '15px', marginBottom: '0px'}}>
              {
                "• Save Your Time for Things That Matter\n\
                • Get Rid of Bad Habits and Stop Struggling\n\
                • Start Achieving Your Goals Quicker\n\
                \n"
              }
            </Body>
            <TextField
                data-cy="optin-email"
                id="optin-email"
                label="email"
                margin="none"
                size="small"
                autoFocus={(window.innerWidth > 0 ? window.innerWidth : window.screen.width) > 700}
                variant="outlined"
                style={{ width: '100%', marginBottom: '10px' }}
                onChange={e => {
                  setemail(e.target.value)
              }}
            />
            
            <div
              style={{
                textAlign: "center",
                marginBottom: "40px"
              }}
            >
                <SquareButton onClick={() => {
                    if (!validateemail(email))
                      toast.error("hmmm, we can't make sense of your email. Please update it and retry.")
                    else {
                      sendOfferRequest( null, email, '5stephabitguide', callback )
                    }
                  }} float={"right"}>
                  {abbutton}
                </SquareButton>
            </div>
            
            {showpolicy ? <div>
              <div style={{fontSize: '10px', textAlign: 'right', color:'grey'}}>By clicking '{abbutton}'</div>
              <div style={{fontSize: '10px', textAlign: 'right', color:'grey'}}> I acknowledge Lateral Products <a style={{color:'grey'}} href='/privacy'>privacy policy</a></div>
            </div> : null}
          </Section>
        </div>
      </GridCVP>
      </BodyDiv>
  );
}

export default Blog;
