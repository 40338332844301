import React, { useEffect } from "react";
import {
  BodyDiv
} from "./components";
import { trackpage } from "./trackpage";

export default function Goals() {
  useEffect(() => {
    document.title = `Goals`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });

  return (
    <BodyDiv style={{ textAlign: "center" }}>
      <title>SliceGoal Goals</title>
      <div style={{display:'flex', alignContent:'center', alignItems:'center', justifyContent:'center', width: '100%', height: '600px'}}>
        <Obj1 name={'test'}/>
        <Obj1 name={'test2'}/>
      </div>
    </BodyDiv>
  );
}

function Obj1({name}) {
  return (
    <div style={{ textAlign: "center" }}>
      {name}
    </div>
  );
}
