import React, { useEffect, useState } from "react";
import {
  Body,
  PointTitle,
  Attribute,
  BodyDiv,
  SquareButton,
  Header,
  GridCVP,
  Section
} from "./components";
import { trackpage, client } from "./trackpage" //sendOfferRequest,
import TextField from '@material-ui/core/TextField'
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { validateemail } from "./functions";
import { abctest33, abtest50 } from "./ABtesting";
import { Dialog, DialogActions } from '@material-ui/core'
import gql from "graphql-tag"
const abcoffer = abctest33('control', 'throat', 'launch')
const abdescription = abtest50('account', 'profile')
const abbutton = abtest50('Claim Your Profile', 'Get Your Habit Profile')



function SliceGoalHabitsOffer() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setemail(params.get('email'))
    document.title = `Free Habit Building Tool`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      abconfig: "&abpromo=" + abcoffer + "&abdescription=" + abdescription
    })
  },[])
  let navigate = useNavigate();
  
  /* function callback(response) {
    console.log("callback: " + response)
    if(response){
      navigate("../")
      toast.success(response, {duration: 4000})
    } else {
      toast.error("Hmmm, something went wrong. Please try again.")
    }
  } */

  var title = {
    'control': 'Take Control Of Your Habits',
    'throat': 'Take Your Habits By The Throat',
    'blast': 'Blast Your Bad Habits Into Pieces'
  }
  const tagline ={
    'control': 'This is your chance to take back control',
    'throat': 'Eat bad habits for breakfast',
    'blast': 'Shape the pieces into something new.'
  }
  const content = 
  `We've created a system that will help you break down habits and let you drive them home step by step.

  • Simply create a profile.
  • We'll guide you through setting up.
  • Target the habits you want.
  • We guide you to driving it home.`
  

  const [email, setemail] = useState("") 
  const [signupsuccess, setSuccess] = useState(false) 
  return (
    <BodyDiv style={{ textAlign: "center" }}>
        <PointTitle align={'center'} style={{ margin: '0 auto', maxWidth: "700px", marginTop: "50px" }}>
          {title[abcoffer]}
        </PointTitle>
        <Attribute style={{margin: '20px'}} align='center'>{tagline[abcoffer]}</Attribute>
        <GridCVP>
          <div
            style={{
              gridArea: "leftcvp",
              display: "flex",
              alignItems: "center",
              marginLeft: 'auto',
              marginRight: 'auto',
              zIndex: '-1'
            }}
          >
          {<div style={{border: 'solid 2px rgba(128, 128, 128, 0.35)', position: 'relative', display: 'flex', alignItems:'center', borderRadius:'10px', height: '350px', width: '300px', boxShadow:' 5px 5px 10px rgba(0, 0, 0, 0.15)'}}>
              <div style={{ display: 'flex', color: 'white', fontWeight:'700', alignItems:'center', fontSize: '10px', background: '#27D0FC', height: '20px', padding: '0px 10px', borderRadius: '10px', position: 'absolute', top:'-10px', left:'-10px'}}>
              Free</div>
              <Header align={'center'} style={{ maxWidth: "700px", margin: "0px 50px" }}>
                Your Habit Profile
              </Header>
            </div>}
          </div>
          <div
            style={{
              gridArea: "rightcvp",
              display: "flex",
              alignItems: "center",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Section>
              {<Body style={{textAlign: "left"}}>
                {content}
              </Body>}
              <div style={{marginTop:'50px'}}>
                  <TextField
                    data-cy="optin-email"
                    id="optin-email"
                    label="email"
                    margin="none"
                    size="small"
                    value={email}
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '10px' }}
                    onChange={e => {setemail(e.target.value)}}
                  />
                  <div style={{display:'flex'}}>
                    <SquareButton 
                      onClick={() => {
                        if (!validateemail(email)) toast.error("hmmm, we can't make sense of that email. Please update it and retry.")
                        else {handleSignupEmailSubmit()} 
                      }}
                      style={{marginLeft:'auto'}}
                      float={"none"}>
                      {abbutton}
                    </SquareButton>
                    
                  </div>
              </div>
              {<div>
              <div style={{fontSize: '10px', textAlign: 'right', color:'grey'}}>By clicking '{abbutton}'</div>
              <div style={{fontSize: '10px', textAlign: 'right', color:'grey'}}> I acknowledge Lateral Products <a style={{color:'grey'}} href='/privacy'>privacy policy</a> and <a style={{color:'grey'}} href='/terms'>terms of service</a>.</div>
              </div>}
            </Section>
          </div>
        </GridCVP>
        <Dialog
            open={signupsuccess}
            onClose={() => setSuccess(false)}
        >
            <DialogActions>
                <form style={{ width: '420px', textAlign: 'center' }}>
                    <div style={{ margin: '20px 0' }} data-cy="success">
                        Success! You&apos;re registered. <br />
                        We sent you an email to verify so we can get you
                        started.
                    </div>
                    <div>Please check your email.</div>
                    <SquareButton
                        id="submitemailpassword"
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                            textTransform: 'lowercase',
                            float: 'right',
                            marginLeft: '5px'
                        }}
                        size="small"
                        onClick={e => {
                            e.preventDefault()
                            navigate("../")
                            toast.success('Got it. Thanks.', {duration: 4000})
                            trackpage({
                              page: window.location.pathname,
                              search: window.location.search.substring(1),
                              abconfig: "&abcoffer=" + abcoffer,
                              action: 'sign up success'
                            })
                            setSuccess(false)
                        }}
                    >
                        ok
                    </SquareButton>
                </form>
            </DialogActions>
        </Dialog>
      </BodyDiv>
  );

  function handleSignupEmailSubmit() {
    if (!validateemail(email)) {
        toast.error("hmmm, we can't make sense of that email.")
        return
    }
    client
        .mutate({
            variables: {
                email: email,
                account: 'client',
                queryStringParams: '&promo=freesignup'
            },
            mutation: MUTATE_SIGNUP
        })
        .then(result => {
            if (result.data.signup)  setSuccess(true)
            else toast.error('Error registering sign up.')
        })
        .catch(error => toast.error(' Error registering sign up.'))
  }
}

const MUTATE_SIGNUP = gql`
    mutation signup(
        $email: String!, 
        $firstname: String, 
        $account: String!, 
        $queryStringParams: String
    ) {
        signup(
            email: $email, 
            firstname: $firstname, 
            account: $account, 
            queryStringParams: $queryStringParams
        )
    }
`

export default SliceGoalHabitsOffer;
