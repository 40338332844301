import React, { useEffect } from "react";
import {
  BlogContainer,
  CenterAlign,
  Header,
  Body,
  PointTitle
} from "./components";

import { trackpage } from "./trackpage";

function About() {
  useEffect(() => {
    document.title = `About`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <div>
      <CenterAlign>
        <BlogContainer>
          <PointTitle align={'left'} style={{ marginLeft: "0px", marginBottom:'10px' }}>
            SliceGoal's Mission
          </PointTitle>
          <Body>
            {
              'SliceGoal pledges to build the best services to help you achieve your goals.'
            }
          </Body>
          <Body>
            {
              "We have built a web app to support you in every way we can imagine."
            }
          </Body>
          <Body>
            {
              "Drawing from the best techniques in productivity and coaching. We have a philosophy of continuous improvement. And we're obsessed about how we can deliver more value to you and help you achieve your goals."
            }
          </Body>
          <Body>
            {
              "We would love to hear from you."
            }
          </Body>
          {/* <Header>Our Approach</Header>
        <Attribute>coming soon...</Attribute> */}
         <Body>
            <Header style={{ marginLeft: "0px" }}>Contact</Header>
            <div style={{fontSize:'16px', fontWeight: '700'}}>{"Daniel Schrader, Founder"}</div>
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto:daniel@slicegoal.com"
            >
              <div style={{fontSize:'16px'}}>{"daniel@slicegoal.com"}</div>
            </a>
            {/*  <Body style={{ marginBottom: "5px" }}>Mail To:</Body>
            <Body>
              {"co Lateral Products, 1-3 Albert St, Blackburn, Victoria, 3130"}
            </Body>*/}
          </Body> 
        </BlogContainer>
      </CenterAlign>
    </div>
  );
}

export default About;
