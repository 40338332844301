import React, { useEffect } from "react";
import {
  Body,
  PointTitle,
  Attribute,
  BlogContainer,
  CenterAlign,
  Header,
  ProductShot,
  SquareButton
} from "./components";
import { trackpage } from "./trackpage";

function BlogCoach() {
  useEffect(() => {
    document.title = `Blog - Coaching and Mentoring. What's the difference?`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <div>
      <title>Coaching and Mentoring - the difference</title>
        <CenterAlign>
          <BlogContainer>
            <PointTitle align={'left'} style={{ marginLeft: "0px" }}>
              The Difference Between Coaching and Mentoring
            </PointTitle>
            <Attribute>2 different ways you can get support</Attribute>
            <Body>
              {
                "Mentoring and coaching are often used interchangeably.\n\
                \n\
                The goal of mentoring and coaching could be argued as the same thing: to use interactions to assist someone to improve their abilities, to discover ideas, to see new insights, to step in new directions, and ultimately to achieve a goal.\n\
                \n\
                So what’s the difference?"
              }
            </Body>
            <div style={{ marginBottom: '50px', marginLeft: '110px'}}><ProductShot/></div>
            <Header>1. Mentoring</Header>
            <Body>
              {
                "A mentor offers answers and ideas of their own to someone for guidance.\n\
                \n\
                The mentor gives insight and guidance to someone using references of their own (the mentor’s) experience. By using their experienced knowledge, a mentor steers someone in a direction, offering steps that have worked for them or other people they know. \n\
                \n\
                In a career setting, a mentor could offer guidance to someone in terms of their career advancement and business relationships. A career mentor (often called a career coach) helps people discuss their career opportunities, set growth targets, build new contacts, and evaluate tools. In this way, a coach acts as a trained counsellor and a role model for the mentors.\n\
                \n\
                Mentoring could be useful for someone seeking help in tactical actions and advice on an approach, from someone who has done that thing before.\n\
                \n "
              }
            </Body>
            <Header>2. Coaching</Header>
            {/*  <DemoImage2 /> */}
            <Body>
              {
                "A coach focuses on helping someone find answers and make choices for themselves without offering the answers directly. The coach will ask questions that help that person explore the possibilities and help them see connections between behaviours and thoughts they may have not seen already. They reflect back what the person is saying and helps visualise their context to help them validate (or invalidate) and navigate it themselves.\n\
                \n\
                Once that person connects the dots on their behaviours, thoughts, and results, they will land on insights that can help them adjust their thinking and behaviours to get new results. This can be an incredible experience and an intense moment of realisation when it happens.\n\
                \n\
                Encouraging someone to focus on the goals and build motivation to tackle them is a big part of coaching too.\n\
                \n\
                Coaching can be extremely effective because it taps internal perceptions and motivations more directly, using intrinsic motivation for a long-lasting effect.\n\n\
                "
              }
            </Body>
            <Header>3. Mentoring and Coaching Together</Header>
            <Body>
              {
                "A coach can sometimes mentor, and a mentor can sometimes coach.\n\
                \n\
                Both a coach and mentor can help keep people accountable, checking in with progress and ensuring people are taking actions that will help them achieve their goals.\n\
                \n\
                Using the intrinsic motivation of your own direction and context is a powerful approach to achieving your goals.\n\
                \n\
                Coaching and mentoring comes in many flavours, Life Coaching, Executive Coaching, Sports Coaching, Sales coaching. Mentors sometimes call themselves a 'coach' when they are mostly mentoring.\n\
                \n\
                You can use SliceGoal to coach yourself: reflect on areas of your life and to set and track progress towards your goals!"
              }
            </Body>
            <div
              style={{
                textAlign: "center",
                marginBottom: "30px"
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.slicegoal.com/app/?page=signup&account=innovator"
              >
                <SquareButton float={"none"} style={{ margin: "70px 0" }}>
                  Set up your coaching wheel
                </SquareButton>
              </a>
            </div>
          </BlogContainer>
        </CenterAlign>
    </div>
  );
}

export default BlogCoach;
