import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { Toaster } from 'react-hot-toast'
import {
  Button,
  HeaderLogo,
  HeaderBar,
  AppGrid,
  Content,
  Footer,
  MenuBar,
  MenuLink,
  MobileMenu,
  Login
} from "../src/components";
import ScrollToTop from "../src/Scroll.js";
//import Teams from "./teams";
import Blog from "../src/Blog.Landing";
import Unsubscribe from "../src/Unsubscribe";
import About from "../src/About";
import Teams from "../src/Teams";
import Coach from "./1on1";
import Self from "../src/Self";
import Home from "../src/Home";
import Goals from "../src/Goals";
import Templates from "../src/Templates";
import BlogCoach from "./Blog.Coaching"
import Blog2022 from "./Blog.2022"
import OfferHabits from "./Offer.Habits.Optin"
import OfferHabitsSuccess from "./Offer.Habits.Optin.Success"
import SliceGoalHabitsOffer from "./Offer.Habits.Optin.SliceGoal"

import { Policy, Privacy, Terms, Refunds } from "../src/Policy";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { trackpage } from "./trackpage";
import Rebrand from "./Rebrand";

const tagManagerArgs = {
  gtmId: "GTM-W4DSF7G"
};

TagManager.initialize(tagManagerArgs);

const menuoptions = [
  { name: "Home", link: "/" },
  { name: "Templates", link: "/Templates" },
  //{ name: "Insights", link: "/Insights" },
  //{ name: "Goals", link: "/Goals" },
  { name: "Blog", link: "/blog/the-big-difference-between-coaching-and-mentoring" },
  { name: "About", link: "/About" }
];

function App() {
  const [menu, openmenu] = useState(false)
  const [menuanchor, setanchor] = useState(null)
  const [tagfilter, setFilter] = useState('')

  window.onbeforeunload = function() {
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      action: "closed"
    });
  };

  return (
    <Router>
      <ScrollToTop>
        <Toaster/>
        <AppGrid>
              {/* <SEO title="SliceGoal" description="Goals and Growth Pillars" /> */}
              <HeaderBar>
                <div
                  style={{ maxWidth: "900px", margin: "0 auto", display: "flex" }}
                >
                  <HeaderLogo />
                  <MenuBar>
                    {menuoptions.map(item => <MenuLink text={item.name} link={item.link} />)}
                  </MenuBar>
                  <Login>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.slicegoal.com/app/"
                    >
                      <Button margin={12}>Log in</Button>
                    </a>
                  </Login>
                  
                  <MobileMenu menuClick={e => menuClick(e)} />
                  <Menu
                    id="long-menu"
                    anchorEl={menuanchor}
                    open={menu}
                    onClose={() => openmenu(false)}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 6,
                        width: 200
                      }
                    }}
                  >
                    {menuoptions.map(option => (
                      <a
                        style={{ color: "black", textDecoration: "none" }}
                        href={option.link}
                      >
                        <MenuItem key={option.name}>{option.name}</MenuItem>
                      </a>
                    ))}
                  </Menu>
                </div>
              </HeaderBar>
              {/* A <Routes> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
              <Content>
                <Routes>
                  <Route exact path="/" element={<Home setFilter={filter => setFilter(filter)}/>}/> {/* this needs to be last because it matches with all paths*/}
                  <Route exact path="blog" element={<Blog/>}/>
                  <Route path="blog/3-ways-to-get-back-on-track-in-2022" element={<Blog2022 />}/>
                  <Route path="blog/the-big-difference-between-coaching-and-mentoring" element={<BlogCoach />}/>
                  <Route path="offer/building-habits" element={<OfferHabits />}/>
                  <Route path="offer/building-habits/success" element={<OfferHabitsSuccess />}/>
                  <Route path="offer/building-habits/slicegoal" element={<SliceGoalHabitsOffer />}/>
                  <Route path="about" element={<About/>}/>
                  <Route path="self-coaching" element={<Self/>}/>
                  <Route path="1on1" element={<Coach/>}/>
                  <Route path="policy" element={<Policy/>}/>
                  <Route path="termsofservice" element={<Terms/>}/>
                  <Route path="privacy" element={<Privacy/>}/>
                  <Route path="refunds" element={<Refunds/>}/>
                  <Route path="blog/rebrand-cavestep-to-slicegoal" element={<Rebrand/>}/>
                  <Route path="teams" element={<Teams/>}/>
                  <Route path="templates" element={<Templates tagfilter={tagfilter} />}/>
                  <Route path="goals" element={<Goals />}/>
                  <Route path="unsubscribe" element={<Unsubscribe />}/>
                </Routes>
              </Content>
              <Footer>
                <div
                  style={{
                    width: "100%",
                    maxWidth: "900px",
                    margin: "0 auto",
                    display: 'flex'
                  }}
                >
                  <MenuLink text={"Privacy"} link={"/privacy"} />
                  <MenuLink text={"Terms of Service"} link={"/termsofservice"} />
                  <div
                    style={{
                      float: "right",
                      marginLeft: "auto",
                      marginRight: '20px',
                      fontFamily: 'Karla'
                    }}
                  >
                    © Lateral Products Pty. Ltd. ABN 55 629 997 066
                  </div>
                </div>
              </Footer>
        </AppGrid>  
      </ScrollToTop>
    </Router>  
  );
  function menuClick(event) {
    event.preventDefault();
    setanchor(event.currentTarget);
    openmenu(true);
  }
}

export default App;
