import React, { useEffect, useState } from "react";
import {
  Body,
  PointTitle,
  Attribute,
  BodyDiv,
  SquareButton,
  Header,
  GridCVP,
  ProfilePicImage,
  Section,
  Button
} from "./components";
import { trackpage, sendOfferRequest } from "./trackpage";
import TextField from '@material-ui/core/TextField'
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import { validateemail } from "./functions";

//AB Test: 1. brevity vs long form 2. story telling vs not 3. one page vs scroll 4. with recommendations vs without
//Want to be able to set up an experiment with a title, define percentages for each variant, name the variant.

import { abtest50 } from "./ABtesting";
//const abofferhero = abtest50("Profile Pic", "Offer Tile");
//const abdetails = abtest50('detail', 'no details');
const abpromo = abtest50('we', 'I');
/*const abtitle = abtest50("The 5 Step Guide to Build Your Habits Without The Struggle in 1 Week - Guaranteed.", '5 Step Exclusive Guide to Build Solid Habits Quickly Without the Pain');
 */
function Blog() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setemail(params.get('email'))
    document.title = `Free Coaching Session`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1),
      abconfig: "&abpromo=" + abpromo
    })
  },[])
  let navigate = useNavigate();
  function callback(response) {
    console.log("callback: " + response)
    if(response){
      toast.success(response, {duration: 4000})
      window.location.replace("https://calendly.com/lateralproducts/habit-coaching-session")
    } else {
      toast.error("Hmmm, something went wrong. Please try again.")
    }
  }
  //const [name, setname] = useState("")
  const [email, setemail] = useState("") 
  return (
    <BodyDiv style={{ textAlign: "center" }}>
        <PointTitle align={'center'} style={{ margin: '0 auto', maxWidth: "700px", marginTop: "50px" }}>
          Free Habit Coaching Session
        </PointTitle>
        <Attribute style={{margin: '20px'}} align='center'>Special Introductory Offer For a limited time only</Attribute>
        <GridCVP>
          <div
            style={{
              gridArea: "leftcvp",
              display: "flex",
              alignItems: "center",
              marginLeft: 'auto',
              marginRight: 'auto',
              zIndex: '-1'
            }}
          >
          {abpromo === 'I' ?
            <ProfilePicImage/> 
            :
            <div style={{border: 'solid 2px rgba(128, 128, 128, 0.35)', position: 'relative', display: 'flex', alignItems:'center', borderRadius:'10px', height: '350px', width: '300px', boxShadow:' 5px 5px 10px rgba(0, 0, 0, 0.15)'}}>
              <div style={{ display: 'flex', color: 'white', fontWeight:'700', alignItems:'center', fontSize: '10px', background: '#27D0FC', height: '20px', padding: '0px 10px', borderRadius: '10px', position: 'absolute', top:'-10px', left:'-10px'}}>
              3 Sessions Left This Week</div>
              <Header align={'center'} style={{ maxWidth: "700px", margin: "0px 50px" }}>
                30 Minute Coaching Session
              </Header>
            </div>
          }
          </div>
          <div
            style={{
              gridArea: "rightcvp",
              display: "flex",
              alignItems: "center",
              marginLeft: 'auto',
              marginRight: 'auto'
            }}
          >
            <Section>
              {abpromo === 'I' ? <Body style={{textAlign: "left"}}>
                {"Hi, I'm Daniel, the founder of SliceGoal. \n\
                I have a mission of revolutionising productivity and achieving goals. \n\n\
                As part of this mission, I have a goal to help 1500 people by the end of 2022. \n\n\
                So this session is free for a limited time."}
              </Body> :
              <Body style={{textAlign: "left"}}>
                {"We have a goal of helping 1500 people by the end of 2022, so this session is free for a limited time.\n\n We work tirelessly to offer you unbelievable improvements to your life habits."}
              </Body>}
              <Body style={{textAlign: 'left', fontWeight:'700'}}>
                {"What you'll get in the session"}
              </Body>
              <Body style={{textAlign: "left", marginLeft: '20px'}}>
                {"• build your confidence\n\
                • clarity of direction\n\
                • techniques for focus"}
              </Body>
              <div style={{marginTop:'50px'}}>
                  <TextField
                    data-cy="optin-email"
                    id="optin-email"
                    label="email"
                    margin="none"
                    size="small"
                    value={email}
                    variant="outlined"
                    style={{ width: '100%', marginBottom: '10px' }}
                    onChange={e => {setemail(e.target.value)}}
                  />
                  <div style={{textAlign: "center"}}>
                    <SquareButton onClick={() => {
                        if (!validateemail(email)) toast.error("hmmm, we can't make sense of that email. Please update it and retry.")
                        else {sendOfferRequest( '', email, 'freeintrosession', callback )} 
                      }} float={"right"}>
                      {"Book Your Session"}
                    </SquareButton>
                    <Button 
                      onClick={() => {
                        navigate("../")
                        toast.success('Got it. Thanks.', {duration: 4000})
                        trackpage({
                          page: window.location.pathname,
                          search: window.location.search.substring(1),
                          abconfig: "&abpromo=" + abpromo,
                          action: 'no thanks'
                        })
                      }} 
                      float={"right"}
                      type={"tertiary"}
                      >
                      {"No Thanks"}
                    </Button>
                  </div>
              </div>
            </Section>
          </div>
        </GridCVP>
        {/* <Body style={{textAlign: "left", fontWeight:'700'}}>
          {"Coaching sessions normally valued at $165"}
        </Body>
        <Body style={{textAlign: "left"}}>
          {"I'm discounting this initial session because I believe it will pay off to share these tools with more people. I want to build my network of awesome people like you, and I believe you'll be a big fan after this! \n\n\
          I've facilitated countless coaching sessions with many people over my years as a coach. And it still surprises and excites me how much positive impact these methods have.\
          Learn first hand why my clients are loyal customers."}
        </Body> 
        <Body style={{textAlign: "left", fontWeight:'700'}}>
          {"Risk-free for you. Lock in your FREE session now!"}
        </Body>
        
        <Body style={{textAlign: "left"}}>
          {"You will learn some of the best tools that have helped my clients.\
          You will get years of experience simplified into a clear approach that gets straight to the heart of things.\n\n\
          "}
        </Body> 
        <Body style={{textAlign: "left", fontWeight:'700'}}>
          {"You'll go through three simple steps"}
        </Body>
        <Body style={{textAlign: "left", marginLeft: '20px', marginBottom: '40px'}}>
          {"• set your goals\n\
          • identify your obstacles\n\
          • plan for next steps\ "}
        </Body>
        <Body style={{textAlign: "left", fontWeight:'700'}}>
          {"I guarantee you will get something from your session! 100% free. You can't lose \n\
          "}
        </Body>
        <Body style={{textAlign: "left"}}> 
          {"Use these tools to achieve anything you want. In your work, personal life, performance, anything you want...\
          My clients get more than $165 worth of value from this session. And they keep coming back."}
        </Body>
        <Body style={{textAlign: "left"}}> 
          {"And if it doesn't work for you for some reason, you don't have to engage me beyond this session. No strings attached!"}
        </Body>
        <Body> 
          {"Trial me as a coach for free!"}
        </Body>
        <Body style={{textAlign: "center", fontSize: '10px', marginBottom: '70px'}}>
          {"P.S. You could miss out on these master tools if you don't act now."}
        </Body> */}
      </BodyDiv>
  );
}

export default Blog;
