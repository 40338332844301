import React, { useEffect } from "react";
import {
  Body,
  PointTitle,
  Attribute,
  BlogContainer,
  CenterAlign,
  Header,
  Button
} from "./components";
import { trackpage } from "./trackpage";

function Blog() {
  useEffect(() => {
    document.title = `3 ways to get back on track in 2022`;
    trackpage({
      page: window.location.pathname,
      search: window.location.search.substring(1)
    });
  });
  return (
    <CenterAlign>
      <BlogContainer>
        <PointTitle style={{ marginLeft: "0px" }}>
        3 powerful ways to get back on track
        </PointTitle>
        <Attribute>Get back on track in 2022</Attribute>
        <Body>
          {
            "2021 was confusing and disruptive. Maybe you were triggered into survival mode (multiple times) and good habits went out the window.\n\
            \n\
            There are three simple things you can do to get back on track and focused for the life you want.\n\
            \n"
          }
        </Body>
        <Header>1. Write down your goals</Header>
        <Body>
          {
            "This is sage wisdom, and making a habit of writing down goals will pay off for you in a big way. Tracking them over time can be even more effective to keep them front of mind. \n\
            \n\
            You're 42% more likely to achieve a goal if you just write it down."
          }
        </Body>
        <div
          style={{
            textAlign: "center",
            marginBottom: "30px"
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.slicegoal.com/app/?page=signup&account=innovator"
          >
            <Button float={"none"} style={{ margin: "70px 0" }}>
              Write your goals in SliceGoal
            </Button>
          </a>
        </div>
        <Header>2. Ask yourself questions</Header>
        <Body>
          {
            "Questions help us shift out of survival/reactive/panic mode and move to higher level thinking. This is proven scientifically, and is a huge benefit of coach or therapist. \n\
            \n\
            Read 'The Coaching Habit' to get some great insights into good questions.\n\
            \n "
          }
        </Body>
        <Header>3. Reflect fortnightly</Header>
        <Body>
          {
            "Big tech companies use this powerful technique with high performing teams. Make time to reflect and understand things you can do to improve. \n\
            \n\
            If you consistently reflect and adapt, then you will get closer to your ideal outcomes."
          }
        </Body>
        <Body>
          {
            "\n\
            \n\
            SliceGoal is designed to help you track your goals and will prompt you with questions to help nudge you back to a healthy mindset.\n\
            \n\
            You can move back to a healthy mindset and focus in 2022 and create huge new value in your life.\n\
            \n\
            "
          }
        </Body>
        <div
        style={{
          textAlign: "center"
        }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.slicegoal.com/app/?page=signup&account=innovator"
          >
            <Button float={"none"} style={{ margin: "20px 0" }}>
              Track your goals for success
            </Button>
          </a>
        </div>
        <Body style={{
            textAlign: "center",
            marginBottom: "200px"
          }}>
          {
            "\n\
            \n\
            Here's to a great 2022!"
          }
        </Body>
      </BlogContainer>
    </CenterAlign>
  );
}

export default Blog;
