export function randomWithProbability() {
  var notRandomNumbers = [1, 1, 1, 1, 2, 2, 2, 3, 3, 4];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  return notRandomNumbers[idx];
}
//this function will return
//1 40%
//2 30%
//3 20%
//4 10%

//Want to be able to set up an experiment with a title, define percentages for each variant, name the variant.
//Want to be able to somehow maintain state, so that if a customer navigates away and back, they have the same page again.
//Need to be able to segment people as well somehow.

export function abtest10(a, b) {
  var notRandomNumbers = [1, 2, 2, 2, 2, 2, 2, 2, 2, 2];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  var result = notRandomNumbers[idx];
  if (result === 1) return a;
  else return b;
} //1 10%, 2 90%.

export function abtest50(a, b) {
  var notRandomNumbers = [1, 2];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  var result = notRandomNumbers[idx];
  if (result === 1) return a;
  else return b;
} //1 50%, 2 50%.

export function abctest33(a, b, c) {
  var notRandomNumbers = [1, 2, 3];
  var idx = Math.floor(Math.random() * notRandomNumbers.length);
  var result = notRandomNumbers[idx];
  if (result === 1) return a;
  if (result === 2) return b;
  else return c;
} //1 33%, 2 33%, 3 33%
